<template>
  <el-drawer
      :title="title"
      size="40%"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose">
    <div>
      <el-table
          border
          stripe
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          @current-change="handleCurrentChange"
          style="width: 100%;cursor: pointer">
        <el-table-column
            property="id"
            label="编号"
            width="120">
        </el-table-column>
        <el-table-column
            property="name"
            label="真实姓名"
            width="120">
        </el-table-column>
        <el-table-column
            property="createTime"
            label="创建时间">
        </el-table-column>
        <el-table-column
            property="phone"
            label="电话">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page.sync="currentPage"
          :total="total"
          :page-size="pagesize"
          @current-change="handleCurrentChangePage"
      />
    </div>
  </el-drawer>
</template>
<script>
export default {
  props: {
    title: {
      type: [String, Object],
      default: "提示",
    },
    data:{
      type:[Object],
      default:{}
    }
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pagesize: 10,
      drawer: false,
      direction: 'rtl',
      currentRow: null,
      tableData:[]
    };
  },
  created() {
    this.getList()
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.drawer=false
          })
          .catch(_ => {});
    },
    async getList(){
      var { data: res } = await this.$http.get("/admin/Activity/getChangeManagerList", {
        params: {
          page: this.currentPage,
          pageSize: this.pagesize,
        },
      });
      this.total=res.data.totalCount
      this.tableData=res.data.list
      console.log(res)
    },
    /**
     * 切换创建人
     * @param val
     * @returns {Promise<void>}
     */
    async toggle(){
      var { data: res } = await this.$http.get("/admin/Activity/changeManager", {
        params: {
          activityId: this.data.id,
          managerId: this.currentRow.id,
        },
      });
      if(res.errorCode==200){
        this.$emit('updata')
        this.$message({
          type: 'success',
          message: '切换成功!'
        });
      }
    },
   handleCurrentChange(val) {
      this.currentRow = val;
      this.$confirm(`是否将创建人切换为“${val.name}”`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {console.log(this.data)
this.toggle()
        this.drawer=false
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消!'
        });
      });
    },
    /**
     * 分页切换
     * @param val
     */
    handleCurrentChangePage(val) {
      this.currentPage = val;
      this.getList();
    },
  }
};
</script>
<style scoped lang="less">

</style>